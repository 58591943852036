/*
import extractFromMeta from '@/mixins/extractFromMeta'
  mixins: [extractFromMeta],
*/

export default {
  methods: {
    typeAndIdFromMeta (meta) {
      if (meta) {
        const { data } = JSON.parse(meta)
        if (data?.id?.includes('gid://v1/Graphs::Graph::SuperNoeud/')) {
          return {
            type: 'supernoeud',
            id: data.id.split('gid://v1/Graphs::Graph::SuperNoeud/')[1],
            data
          }
        } else if (data?.id?.includes('gid://v1/Graphs::Graph::Super/')) {
          return {
            type: 'graph',
            id: data.id.split('gid://v1/Graphs::Graph::Super/')[1],
            data
          }
        } else if (data?.id?.includes('BoutiqueItem/') && data.type === 'boutiqueItem') {
          return {
            type: 'boutiqueItem',
            id: data.id.split('/BoutiqueItem/')[1],
            data
          }
        }
      }
      return {
        type: null,
        id: null,
        data: {}
      }
    }
  }
}

<script>
import ChatControls from '@/mixins/chatControls.js'
import ScoreFunctions from '@/mixins/scoreFunctions.js'
import FormatDate from '@/mixins/formatDate.js'
import calendrierCalendrierElements from '@/mixins/calendrierCalendrierElements.js'

import TextPattern from '@/components/shared/textPattern.vue'
import NoteEnfant from '@/components/enfants/noteEnfant'
import RemainingDays from '@/components/shared/remainingDays'
import { flatten, uniqBy } from 'lodash-es'

export default {
  name: 'DevoirCard',
  mixins: [ChatControls, ScoreFunctions, FormatDate, calendrierCalendrierElements],
  components: {
    RemainingDays,
    NoteEnfant,
    TextPattern
  },
  props: {
    data: { type: Object, required: true },
    maxWidth: { required: false, type: Number, default: 0 },
    accesRapide: { required: false, type: Boolean, default: false }
  },
  computed: {
    idBoutique () {
      if (this.minimalCalendrierElement?.resultat?.[0]?.meta) {
        let val = JSON.parse(this.minimalCalendrierElement.resultat[0].meta)
        return val.data.id.split('BoutiqueItem/')[1]
      }
      return null
    },
    urlDevoir () {
      let temp = '/devoirs/'
      if (this.data && this.data.identifiant) {
        temp += this.data.identifiant + '?identifiant=true'
      } else if (this.data && this.data.batch) {
        temp += this.data.batch + '?batch=true'
      }
      return temp
    },
    idDevoir () {
      if (this.data && this.data.exercices && this.data.exercices[0] && this.data.exercices[0].devoir) {
        return this.data.exercices[0].devoir
      } else {
        return null
      }
    },
    getDevoirImage () {
      let image = null
      if (this.supergraph && this.supergraph.image) {
        image = {
          src: 'https://cdn-1.dokoma.com' + this.supergraph.image.medium_image,
          lazy: 'https://cdn-1.dokoma.com' + this.supergraph.image.thumbnail
        }
      } else if (this.supernoeud && this.supernoeud.graph && this.supernoeud.graph.image) {
        image = {
          src: 'https://cdn-1.dokoma.com' + this.supernoeud.graph.image.medium_image,
          lazy: 'https://cdn-1.dokoma.com' + this.supernoeud.graph.image.thumbnail
        }
      } else if (this.boutiqueItem?.images) {
        const urlImage = this.boutiqueItem.images.find(e => e.tagsVisibles.find(t => t.id === 'details-graphiques_image-couverture'))
        image = {
          src: urlImage.medium_image,
          lazy: urlImage.thumbnail
        }
      }
      return image
    },
    nbUsagers () {
      return this.getElementsUsagersFromCceList(this.minimalCalendrierElement?.resultat)
    },
    nbQuestions () {
      let temp = []
      let somme = 0
      if (this.questions && this.questions?.resultat && this.questions?.resultat?.length > 0) {
        uniqBy(this.questions.resultat, 'meta').forEach((el) => {
          if (el.contenu?.contenus) {
            const parseData = JSON.parse(el.meta)
            if (parseData.data && parseData.data.contenu && parseData.data.contenu.length > 0) {
              temp.push(el.contenu.contenus.filter(e => parseData.data.contenu.find(c => c === e.id)))
            }
          }
        })
      }
      flatten(temp?.map(e => e)).forEach(e => {
        somme += JSON.parse(e.data).nb_questions
      })
      return somme
    },
    isMobile () {
      return this.$store.state.App.mobileTemplate
    },
    dataStatus () {
      return this.data?.identifiantStatut ? this.data?.identifiantStatut?.toLowerCase() : this.data?.status?.toLowerCase()
    }
  },
  data () {
    return {
      supergraph: null,
      supernoeud: null,
      minimalCalendrierElement: null
    }
  },
  apollo: {
    elements: {
      query: require('@/graphql/queries/v2/minimalCalendrierCalendrierElements.gql'),
      variables () {
        return {
          filtre: {
            identifiants: [this.data.identifiant]
          }
        }
      },
      update (data) {
        return uniqBy(data.calendrierCalendrierElements.resultat, 'meta')
      },
      skip () { return ![this.data.identifiant] }
    },
    questions: {
      query: require('@/graphql/queries/v2/opti/calendrierCalendrierElementsGraphArbre.gql'),
      variables () {
        return {
          filtre: {
            identifiants: [this.data.identifiant]
          }
        }
      },
      update (data) {
        return data.calendrierCalendrierElements
      },
      skip () { return ![this.data.identifiant] }
    },
    minimalCalendrierElement: {
      query: require('@/graphql/queries/v2/minimalCalendrierCalendrierElements.gql'),
      variables () {
        return {
          filtre: {
            identifiants: [this.data.identifiant]
          },
          batch: true
        }
      },
      update (data) {
        return data.calendrierCalendrierElements
      }
    },
    boutiqueItem: {
      query: require('@/graphql/queries/v2/boutiqueItemBy.gql'),
      variables () {
        return {
          attrs: { slug: this.idBoutique }
        }
      },
      update (data) {
        return data.boutiqueItemBy
      },
      watchLoading (isLoading) {
        this.boutiqueLoading = isLoading
      },
      skip () {
        return (!this.idBoutique || !this.data)
      }
    },
    supergraph: {
      query: require('@/graphql/queries/plus/supergraphMin.gql'),
      client: 'plus',
      variables () {
        return {
          id: this.idDevoir
        }
      },
      update (data) {
        return data.super_graph
      },
      skip () {
        return ((this.data && this.data.exercices && this.data.exercices[0] && this.data.exercices[0].type !== 'graph') || !this.idDevoir)
      }
    },
    supernoeud: {
      query: require('@/graphql/queries/plus/supernoeudMin.gql'),
      client: 'plus',
      variables () {
        return {
          id: this.idDevoir
        }
      },
      update (data) {
        return data.super_graph_noeud
      },
      skip () {
        return ((this.data && this.data.exercices && this.data.exercices[0] && this.data.exercices[0].type !== 'supernoeud') || !this.idDevoir)
      }
    }
  },
  methods: {
    getTest (value) {
      console.log('la value', value)
    }
  }
}
</script>

<template lang='pug'>
v-card.base-card.mb2(v-if='data', :style='{maxWidth: maxWidth > 0 ? maxWidth + "px" : "unset"}', :class='{ accesRapide : accesRapide}')
  .card-image
    v-img(v-if='getDevoirImage', :src='getDevoirImage.src', :lazy-src='getDevoirImage.lazy')
    text-pattern(v-else, :data='!!data.titre ? $t("menu.devoir") : $t("exercices.exercice-sans-titre")', :color='"var(--v-secondary-base)"', :size='40', :opacity='0.25', :quantity='100')
  template
    template(v-if='!accesRapide')
      div.card-header
        template(v-if='dataStatus')
          v-chip.status__chip(v-if='dataStatus === "en_attente" || dataStatus === "en_cours"' :small='!isMobile' :x-small='isMobile' color='secondary') {{ $t("messages.en-cours") }}
          v-chip.status__chip(v-else-if='dataStatus === "nouveau" || dataStatus === "actif"' :small='!isMobile' :x-small='isMobile' color='secondary') {{ $t("messages.nouveau") }}
          v-chip.status__chip.b(v-else-if='dataStatus === "finished" || dataStatus === "archive"' :small='!isMobile' :x-small='isMobile' :color='getColorFromResult(data.mark || 0)') {{ data.mark ? Math.round(data.mark) + '%' : $t('exercices.non-remis')}}
          v-chip.status__chip(v-else :small='!isMobile' :x-small='isMobile' color='secondary') {{ $t("messages.en-cours") }}
        div.flex-grow-1
    div.flex-grow-1
    v-sheet.relative()
      .card-infos.w-100(style='padding: 0px 4px 2px 4px')
        div(style='display:flex')
          v-chip.primary.lighten-4.secondary--text(small, outlined)
            font-awesome-icon.f6.mr1(:icon="['fad', 'users-class']")
            span(v-if='Number.isFinite(nbUsagers)') {{ $tc('eleves.eleve-multiple', nbUsagers,{nb:nbUsagers}) }}
          v-chip.primary.lighten-4.secondary--text(v-if='elements && elements.length', small, outlined)
            font-awesome-icon.f6.mr1(:icon="['fad', 'file-edit']")
            //- span {{data.exercices.length}} exercice{{data.exercices.length > 1 ? 's' : ''}}
            span {{elements && elements.length ? elements.length : 0}} {{ $tc('exercices.element-multiple', elements && elements.length ? elements.length : 0)}}
          v-chip.primary.lighten-4.secondary--text(v-if='nbQuestions && !accesRapide', small, outlined)
            font-awesome-icon.f6.mr1(:icon="['fad', 'file-edit']")
            span {{nbQuestions}} {{ $tc('exercices.question-multiple', nbQuestions)}}

        h3.mt2.ml2.secondary--text {{data.titre ? data.titre : 'Devoir sans titre'}}
      v-card-actions(style='padding: 16px', v-if='data && (data.identifiant || data.batch)')
        v-btn(v-if='data && (data.identifiant || data.batch)', :to='urlDevoir', rounded, depressed, color='secondary', block, outlined) {{ $t('action.acceder') }}
</template>
<style lang='sass' scoped>
.accesRapide
  margin-right: 30px
  height: 325px
  width: 220px
  .card-image
    .v-image
      height: 100%
  .card-infos
    div
      margin-left: 2px
    h3
      height: 50px
      padding-right: 5px
</style>

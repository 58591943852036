<script>
import DevoirCard from '@/components/presentation/devoirCard.vue'
import TableauDevoirs from '@/components/devoirs/tableauDevoirs.vue'
import extractFromMeta from '@/mixins/extractFromMeta'
import { orderBy, flatten, debounce } from 'lodash-es'
import ContentHandleList from '@/components/bibliotheque/biblioHandleList.vue'
export default {
  name: 'Devoirs',
  components: {
    DevoirCard,
    TableauDevoirs,
    ContentHandleList
  },
  mixins: [extractFromMeta],
  data () {
    return {
      disabledClick: false,
      searchDevoirs: null,
      finished: true,
      active: true,
      filterType: null,
      searchDevoir: null,
      filters: [
        {
          title: this.$t('exercices.date-echeance'),
          value: 'endDate'
        },
        {
          title: this.$t('partage.groupe'),
          value: 'groupName'
        },
        {
          title: this.$t('exercices.date-echeance'),
          value: 'endDate'
        }
      ]
    }
  },
  apollo: {
    listDevoirs: {
      query: require('@/graphql/queries/v2/calendrierCalendrierElements.gql'),
      variables () {
        const filtre = {}
        if (this.activePeriode) {
          filtre.debut = this.periodeActive.debut
          filtre.fin = this.periodeActive.fin
        }
        if (this.usagers?.length > 0) {
          filtre.usagers = this.usagers
        }
        filtre.metas = [{ type: 'serie' }, { type: 'exercice' }, { type: 'boutiqueItem' }, { contenu: { type: 'ITEM' } }, { contenu: { type: 'ARBRE' } }]
        return {
          identifiant: true,
          batch: true,
          limit: 100,
          filtre,
          contenus: { type: 'QUESTION' }
        }
      },
      update (data) {
        return data.calendrierCalendrierElements.resultat
      }
    }
  },
  computed: {
    showGroupCreation: {
      set (val) { this.$store.commit('Dialogs/setGroupCreation', val) },
      get () { return this.$store.state.Dialogs.showGroupCreation }
    },
    devoirsRecentsV2 () {
      const dr = this.$store.state.Exercices.devoirsRecent
      if (!dr) { return [] }

      const allDevoirsRecents = flatten(Object.values(dr))
      const filteredDevoirsRecents = allDevoirsRecents.filter(p => p.finAt ? this.$dayjs().isBefore(p.finAt) : true)
      const orderedDevoirsRecents = orderBy(filteredDevoirsRecents, 'updatedAt', 'desc')
      const optiDevoirsRecents = orderedDevoirsRecents.map(d => {
        const type = d.meta ? this.typeAndIdFromMeta(d.data).type : 'boutiqueItem'
        const devoirId = d.meta ? this.typeAndIdFromMeta(d.data).id : d.contenu.slug
        const exercices = type && devoirId ? [{ type, devoir: devoirId, statut: d.statut }] : []

        return {
          batch: d.batch,
          identifiant: d.identifiant,
          debutAt: d.debutAt,
          finAt: d.finAt,
          titre: d.titre,
          description: d.description,
          from: d.usager,
          status: d.statut,
          identifiantStatut: d.identifiantStatut,
          mark: null,
          nombreUsagers: d.elementsUsagers.total,
          updatedAt: new Date(d.updatedAt).getTime() / 1000,
          exercices
        }
      })
      return optiDevoirsRecents
    },
    statutsFiltre () {
      const list = []
      if (this.finished) {
        list.push('ARCHIVE')
      }
      if (this.active) {
        list.push('ACTIF')
      }
      return list
    }
  },
  watch: {
    '$route': {
      deep: true,
      immediate: true,
      handler (newVal) {
        this.$store.dispatch('Exercices/fetchDevoirsRecent')
      }
    }
  },
  methods: {
    changeSearch: debounce(function changeSearch (val) {
      this.searchDevoir = val
    }, 1000, { trailing: true })
  }
}
</script>

<template lang='pug'>
  .bibliotheque-index.split-page
    div.biblio-wrapper
      content-handle-list(v-if='listDevoirs', origin='devoirs', :produits='listDevoirs')
      .produits-showcase
        .produit-index.split-page
          .page-header(color='primary')
            .header__inner-content
              .header-title()
                //- div.secondary-title Sous-titre
                div.main-title {{ $t('menu.gestion-devoirs') }}
              //- .header-description Gestion et création de devoirs pour vos élèves

          .page-actions
            v-card(color='primary', :to='{ name: "devoirEdition___" + $i18n.locale, params: { lang: $i18n.locale } }', v-ripple)
              v-card-title {{ $t('action.creer-devoir') }}
          v-toolbar.filters-toolbar(color='dark', dark)
            div.flex.items-center.w-100
              v-text-field(v-model='searchDevoirs', solo, rounded, light, hide-details, dense, :placeholder='$t("action.recherche-placeholder")', style='max-width:365px', @input='changeSearch')
              div.flex-grow-1
              v-menu(offset-y, :close-on-content-click='false')
                template(v-slot:activator='{ on }')
                  v-btn.mr2(outlined, small, @click.prevent.stop='', v-on='on')
                    | Statuts
                v-card
                  v-list(subheader)
                    v-subheader Filtres
                    v-divider
                    v-list-item
                      v-list-item-content
                        v-checkbox(v-model='finished', :label="$t('exercices.devoir-termine')", hide-details, style='margin-top:0')
                    v-list-item
                      v-list-item-content
                        v-checkbox(v-model='active', :label="$t('exercices.devoir-en-cours')", hide-details, style='margin-top:0')
          .page__main-content.mt3
            section.fullsize-section.animated.fadeIn(v-if='!searchDevoirs && devoirsRecentsV2.length > 0')
              .section-subtitle__header
                h2.section-subtitle {{ $t('widget.devoirs-recents')}}
              perfect-scrollbar.section__scroller(v-dragscroll, @dragscrollstart="disabledClick = true", @dragscrollend="disabledClick = false", :class='{"disable-click" : disabledClick}')
                .section__wrapper(style="max-width:320px; margin: 0")
                  template(v-for='(devoir, index) in devoirsRecentsV2')
                    devoir-card.mr4(:data='devoir', :maxWidth='320')
            section.fullsize-section
              .section-subtitle__header
                h2.section-subtitle {{ $t('exercices.mes-devoirs') }}
              tableau-devoirs(:rounded='true' :maxShow='10' :showExpired="false", :statutsFiltre='statutsFiltre', :searchDevoir='searchDevoir')
</template>
<style lang='sass'>
  @import 'src/styles/pages/_bibliotheque'
  @import 'src/styles/components/bibliotheque/_produit'
</style>
